import { Link } from "gatsby"
import React, { lazy, ReactElement, useEffect, useState } from "react"
import Button from "../components/Button/Button"
import Input from "../components/Input/Input"
import UserConfirm from "../components/UserConfirm"
import DefaultLayout from "../templates/DefaultLayout"
import { toast } from "react-toastify"
import Auth from "@aws-amplify/auth"
import { navigate } from "@reach/router"
import Seo from "../components/seo"
interface Props {}

export default function SignUp({}: Props): ReactElement {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState("")
  const [givenName, setGivenName] = useState("")
  const [familyName, setFamilyName] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [password, setPassword] = useState("")
  const [isConfirmationPage, setShowConfirmationPage] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [terms, setTerms] = useState(false)
  useEffect(() => {
    Auth.currentSession()
      .then(res => {
        setShowSignUp(false)
      })
      .catch(() => {
        setShowSignUp(true)
      })
      .finally(() => setLoading(false))
  }, [])

  const redirectToLogin = (): void => {
    navigate("/sign-in")
  }
  const signUp = async () => {
    setLoading(true)
    const toastId = toast("Loading....", {
      progress: "0",
    })
    try {
      toast.dismiss(toastId)
      if (!terms) {
        toast("You have not agreed to the Terms & Conditions.", {
          type: "error",
        })
        return
      }
      if (password !== confirmPassword) {
        toast("Confirm Password doesn't match with Password.", {
          type: "error",
        })
        return
      }
      const res = await Auth.signUp({
        password,
        username: username.replace(/\s+/g, ""),
        attributes: {
          family_name: familyName,
          given_name: givenName,
        },
      })
      if (!res.userConfirmed) {
        toast(
          "You have been sent a verification code to the provided email address.",
          {
            type: "info",
          }
        )
        setShowConfirmationPage(true)
      } else {
        redirectToLogin()
      }
    } catch (e: any) {
      toast.dismiss(toastId)
      toast(e?.message, {
        type: "error",
      })
    } finally {
      setLoading(false)
    }
  }
  if (loading) {
    return (
      <DefaultLayout>
        <div className="min-h-[80vh] flex items-center justify-center">
          Loading...
        </div>
      </DefaultLayout>
    )
  }
  if (!showSignUp) {
    return (
      <DefaultLayout>
        <div className="min-h-[80vh] flex  justify-center items-start">
          <div className="max-w-[500px] w-full lg:p-10 p-5 rounded-md border my-4 bg-br-primary-blue shadow-lg bg-opacity-5 mx-4">
            <h2 className="font-bold text-xl lg:text-3xl text-br-primary-blue">
              Log out?
            </h2>

            <p className="my-4">
              Before you can create a new account, you will need to log-out of
              the current account.
            </p>
            <div className="flex justify-end">
              <Button
                className="mr-4"
                onClick={() => {
                  Auth.signOut().then(() => {
                    setShowSignUp(true)
                  })
                }}
              >
                Logout
              </Button>
              <Button
                invert
                onClick={() => {
                  window.location.href = "/"
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </DefaultLayout>
    )
  }
  if (isConfirmationPage) {
    return <UserConfirm userName={username} onComplete={redirectToLogin} />
  }
  return (
    <DefaultLayout>
      <Seo title="Sign Up" description="" />
      <div className="min-h-[80vh] flex  justify-center items-start">
        <div className="max-w-[500px] w-full lg:p-10 p-5 rounded-md border my-4 bg-br-primary-blue shadow-lg bg-opacity-5 mx-4">
          <h2 className="font-bold text-xl lg:text-3xl text-br-primary-blue">
            Sign up
          </h2>
          <Input
            placeholder="Email"
            type="email"
            className="mt-10"
            value={username}
            onChange={({ target: { value } }) => setUsername(value)}
            name="email"
          />
          <Input
            placeholder="Given Name"
            className="mt-6"
            value={givenName}
            onChange={({ target: { value } }) => setGivenName(value)}
          />
          <Input
            placeholder="Family Name"
            className="mt-6"
            value={familyName}
            onChange={({ target: { value } }) => setFamilyName(value)}
          />
          <form>
            <Input
              placeholder="Password"
              type="password"
              className="mt-6"
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
            />
            <Input
              placeholder="Confirm Password"
              type="password"
              className="mt-6"
              value={confirmPassword}
              onChange={({ target: { value } }) => setConfirmPassword(value)}
            />
          </form>
          <ol className="list-disc mt-4 ml-10 text-br-primary-blue">
            <li className="mt-2">Password must contain a lower case letter</li>
            <li className="mt-2">Password must contain an upper case letter</li>
            <li className="mt-2">Password must contain a special character</li>
            <li className="mt-2">Password must contain a number</li>
            <li className="mt-2">
              Password must contain at least 8 characters
            </li>
          </ol>
          <div className="flex  items-center gap-x-2 mt-4">
            <input
              type="checkbox"
              id="terms-and-conditions"
              checked={terms}
              onChange={({ target: { checked } }) => setTerms(checked)}
            />{" "}
            <label htmlFor="terms-and-conditions">
              I have read and agree to the{" "}
              <Link to="/terms" className="text-br-primary-blue underline">
                {" "}
                Terms &amp; Conditions
              </Link>
            </label>
          </div>
          <Button
            className="mt-6 text-lg !px-10 w-full"
            onClick={() => {
              signUp()
            }}
          >
            Sign up
          </Button>
          <div className="text-center mt-3">
            Already have an account?{" "}
            <Link
              to="/sign-in/"
              className="underline text-br-primary-blue font-medium"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
